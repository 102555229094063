import { Hamburger } from "./Hamburger";

export class Header {

    private _hamburger: Hamburger;

    constructor() {

    }


    public init() {

        this._hamburger = new Hamburger();
        this._hamburger.init();

        this.setupMenu();
    }


    private setupMenu() {

        const links: NodeListOf<HTMLElement> = document.querySelectorAll( '.c-navigation__link' );
        for ( let i: number = 0; i < links.length; i++ ) {

            links[ i ].addEventListener( 'click', () => this.handleLinkClicked() );

        }

    }


    private handleLinkClicked() {

        this._hamburger.closeMenu();

    }


    // private handleLinkMouseEnter( event: MouseEvent ) {
        
    //     const target: EventTarget = event.relatedTarget;
    //     if ( target !== this._active_link ) this._active_link.classList.add( 's-visual-inactive' );
        
    // }

    // private handleLinkMouseLeave( event: MouseEvent ) {

    //     if ( this._active_link ) this._active_link.classList.remove( 's-visual-inactive' );
        
    // }

}