import Flickity from 'Flickity';

export class Carousel {


    private _options: Object = {

        setGallerySize: false,
        wrapAround: true

    }

    constructor() {

    }


    public init() {

        const carousels: NodeListOf<HTMLElement> = document.querySelectorAll( '.c-carousel__carousel' );
        for ( let i: number = 0; i < carousels.length; i++ ) this.setupCarousel( carousels[ i ] );

    }


    private setupCarousel( carousel_element: HTMLElement ) {

        const carousel: Flickity = new Flickity( carousel_element, this._options );

        const images: NodeListOf<HTMLImageElement> = carousel_element.querySelectorAll( 'img' );
        for ( let i: number = 0; i < images.length; i++ )
            images[ i ].addEventListener( 'load', () => this.handleImageLoaded( carousel ) );

    }


    private handleImageLoaded( carousel: Flickity ) {

        carousel.reposition();

    }
    
}