import { Header } from "./modules/Header";
import { Carousel } from "./modules/Carousel";

class Main {

    private _body: HTMLBodyElement;

    private _header: Header;
    private _carousel: Carousel;

    constructor() {

        document.addEventListener('DOMContentLoaded', () => this.init());

    }


    private init() {

        this.setupVars();

        if ( !this._body.classList.contains( 'wp-admin' ) ) {

            // this._history_manager = new HistoryManager( [ '.c-main__inner', '.c-navigation' ] );
            // this._page_transition = new PageTransition();

            // console.log('init', history);

            // this.setupListeners();
            this.setupContent();
            
            this.checkIfTouch();

        }
        
    }


    private setupVars() {

        this._body = <HTMLBodyElement>document.querySelector('body');

    }


    private checkIfTouch() {

        window.addEventListener( 'touchstart', () => {

            document.body.classList.add( 'touch-device' );

        }, false );

    }


    private setupContent() {

        this._header = new Header();
        this._carousel = new Carousel();

        this._header.init();

        this.setupNewContent();

    }


    private setupNewContent() {

        this._carousel.init();

    }

}

new Main();