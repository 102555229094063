import gsap from "gsap";

export class Hamburger {


    private _menu_open: boolean = false;
    private _line_one: HTMLElement;
    private _line_two: HTMLElement;
    private _line_three: HTMLElement;

    constructor() {

    }


    public init() {

        const hamburger_element: HTMLElement = document.querySelector( '.c-hamburger' );
        if ( hamburger_element ) {

            window.addEventListener( 'closeOverlay', () => this.closeMenu() );

            this._line_one = hamburger_element.querySelector( '.c-hamburger__line--one' );
            this._line_two = hamburger_element.querySelector( '.c-hamburger__line--two' );
            this._line_three = hamburger_element.querySelector( '.c-hamburger__line--three' );

            hamburger_element.addEventListener( 'click', ( event: Event ) => this.handleHamburgerClicked( event ) );

        }
    }


    private handleHamburgerClicked( event: Event ) {

        event.preventDefault();

        document.body.classList.toggle( 's-menu-open' );
        this._menu_open = !this._menu_open;

        if ( this._line_one && this._line_two ) {
            if ( this._menu_open ) {

                // @ts-ignore
                gsap.to( [this._line_one, this._line_two], { duration: .2, y: 0, onComplete: () => {

                    // @ts-ignore
                    gsap.to( this._line_one, { duration: .2, rotation: -45 } );
                    // @ts-ignore
                    gsap.to( this._line_two, { duration: .2, rotation: 45 } );
                    // @ts-ignore
                    gsap.set( this._line_three, { opacity: 0 } );

                } } );

            } else {

                this.closeIconAnimation(); 
                
            }
        }

        return false;

    }


    public closeMenu() {

        this._menu_open = false;
        document.body.classList.remove( 's-menu-open' );

        if ( this._line_one && this._line_two && this._line_three ) this.closeIconAnimation();

    }


    private closeIconAnimation() {

        // @ts-ignore
        gsap.to( [ this._line_one, this._line_two], { duration: .2, rotation: 0, onComplete: () => {

            // @ts-ignore
            gsap.to( this._line_one, { duration: .2, y: -5 } );
            // @ts-ignore
            gsap.to( this._line_two, { duration: .2, y: 5 } );
            // @ts-ignore
            gsap.set( this._line_three, { opacity: 1 } );

        } } );

    }

}